(function ($) {
  $(document).ready(function () {
    introHotels();
    removeIntroHotels();

    //mobile bar
    if ($(".hotels").length > 0) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      var vh = window.innerHeight * 0.01; // Then we set the value in the --vh custom property to the root of the document

      document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
      introHotelLink();
    }

    //mobile bar
    window.addEventListener("orientationchange", function () {
      if ($(".hotels").length > 0 && $(window).width() < 768) {
        var nh = window.innerHeight;
        setTimeout(function () {
          waitForChange(window.innerHeight, 10);
        }, 10);
        this.document.documentElement.offsetLeft;
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
      }
    });
  });

  //mobile bar
  function waitForChange(oldh, time) {
    var nh = window.innerHeight;
    if (time < 600 && oldh == nh) {
      setTimeout(function () {
        waitForChange(nh, time + 10);
      }, 10);
    } else {
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
    }
  }

  //mobile bar
  function orientationChanged() {
    var timeout = 120;
    return new window.Promise(function (resolve) {
      var go = function (i, height0) {
        window.innerHeight != height0 || i >= timeout
          ? resolve()
          : window.requestAnimationFrame(function () {
              go(i + 1, height0);
            });
      };
      go(0, window.innerHeight);
    });
  }

  function introHotels() {
    if ($(".hotels").length > 0) {
      $("body").addClass("overflow-hidden");
      if ($(window).width() > 767) {
        $(document).on("mouseenter", ".hotel-wrapper", function () {
          var $this = $(this);

          $this.addClass("on-focus");
          $(".hotel-wrapper").not($this).addClass("shrinked");
        });
        $(document).on("mouseleave", ".hotel-wrapper", function () {
          var $this = $(this);
          $this.removeClass("on-focus");
          $(".hotel-wrapper").not($this).removeClass("shrinked");
        });
      }
    }
  }

  function removeIntroHotels() {
    if ($(".hotels").length > 0) {
      if ($(window).width() < 768) {
        $(document).on("mouseenter", ".hotel-wrapper", function () {
          var $this = $(this);
          $this.removeClass("on-focus");
          $(".hotel-wrapper").not($this).removeClass("shrinked");
        });
        $(document).on("mouseleave", ".hotel-wrapper", function () {
          var $this = $(this);
          $this.removeClass("on-focus");
          $(".hotel-wrapper").not($this).removeClass("shrinked");
        });
      }
    }
  }

  function introHotelLink() {
    $(".hotel-wrapper").on("click", function () {
      if ($(window).width() < 768) {
        var destination = $(this).find("a.hotel-link-explore").attr("href");
        window.location.href = destination;
      }
    });
  }
})(jQuery);
